import './css/App.css';

import IClogo from './assets/image/logo.png';
import IClogow from './assets/image/logo_w.png';
import bgMain from './assets/image/bgMain.png';
import bgQuick from './assets/image/bgSection2.png';
import IcTel from './assets/image/icon_tel.png';
import IcOrder from './assets/image/icon_order.png';
import IcDevice from './assets/image/icon_device.png';
import IcWTel from './assets/image/icon_phone-white.png';
import IcApple from './assets/image/icon-apple.svg';
import IcGoogle from './assets/image/icon-google.svg';
import IcModel1 from './assets/image/icon_model1.png';
import IcModel2 from './assets/image/icon_model2.png';
import IcModel3 from './assets/image/icon_model3.png';
import IcModel4 from './assets/image/icon_model4.png';
import IcModel5 from './assets/image/icon_model5.png';
import IcModel6 from './assets/image/icon_dump.png';
import IcProp1 from './assets/image/img_order1.png';
import IcProp2 from './assets/image/img_order2.png';
import IcProp3 from './assets/image/img_order3.png';
import bgSolution1 from './assets/image/img_solution1.png';
import bgSolution2 from './assets/image/img_solution2.png';
import bgSolution3 from './assets/image/img_solution3.png';
import bgSolution4 from './assets/image/img_solution4.png';
import IcBlog from './assets/image/icon_blog.png';
import IcYoutube from './assets/image/icon_youtube.png';
import IcDown from './assets/image/icon_download.png';
import IcQuest from './assets/image/icon_main_quest.png';
import IcQuest2 from './assets/image/icon_quest2.png';
import bgNowList from './assets/image/bg_nowlist.png';
import bgWShadow from './assets/image/white_shadow.png';
import bgBButton from './assets/image/bg_live_button.png';
import {useEffect, useState} from "react";
import axios from "axios";
import LiveNowItem from "./component/LiveNowItem";
import OrderModal from "./modal/OrderModal";

function App() {
    const [isMenuShow, setIsMenuShow] = useState(false);
    const [isDownShow, setIsDownShow] = useState(false);
    const [isSMS, setIsSMS] = useState(false);
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [orders, setOrders] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [isOrder, setIsOrder] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll); //clean up
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsMenuShow(true);
        } else {
            setIsMenuShow(false);
        }

        if (window.scrollY > 480) {
            setIsDownShow(true);
        } else {
            setIsDownShow(false);
        }
    };

    const showSMS = () => {
        setName('');
        setMobile('');
        setIsSMS(true);
    }

    const sendSMS = () => {
        if (!name) {
            window.alert("성명을 입력해주세요.");
        } else if (!mobile) {
            window.alert("연락처를 입력해주세요.");
        } else {
            const isMobile = /[0-9]{8,11}$/.test(mobile.replace(/-/g, ""));
            console.log(mobile, isMobile);
            if (!isMobile) {
                window.alert("연락처 형식이 맞지 않습니다.\n '-'을 제외한 숫자만 입력해주세요.");
            } else {
                try {
                    const headers = {
                        'Context-Type': "application/json"
                    }
                    axios.post('/sms/send', {name: name, mobile: mobile}, {headers}).then(response => {
                        const data = response.data;
                        if (data.status === 200) {
                            window.alert("입전신청 되었습니다.\n빠른 시일안에 연락드리겠습니다.");
                        } else {
                            window.alert(data.message);
                        }
                    });
                } catch (e) {
                    window.alert("전송중 에러가 발생했습니다.\n잠시후 다시 진행해주세요.");
                }
                setIsSMS(false);
            }
        }
    }

    const goPlaystore = () => {
        goURL('https://play.google.com/store/apps/details?id=kr.co.jangpa.app');
    }

    const goAppstore = () => {
        goURL('https://apps.apple.com/kr/app/%EC%9E%A5%EB%B9%84%ED%8C%8C%ED%8A%B8%EB%84%88/id6474229747');
    }

    const goBlog = () => {
        goURL('https://blog.naver.com/jangpa0101');
    }

    const goYoutube = () => {
        goURL('https://www.youtube.com/@user-tx5cs7np9d');
    }

    const goOrder = () => {
        goURL('https://order.jangpa.co.kr');
    }

    const goURL = (url) => {
        window.open(url, '_blank', 'noopener, noreferrer');
    }

    const loadOrder = () => {
        axios.get("/api/order").then((data) => {
            if (data.data.status === 200) {
                setOrders(data.data.result);
            }
        });
    }

    const loadBlog = () => {
        axios.get("/api/blog").then((data) => {
            if (data.data.status === 200) {
                setBlogs(data.data.result.list);
            }
        });
    }
    const goTel = () => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            window.open('tel:15552838');
        }
    }

    useEffect(() => {
        loadOrder();
        loadBlog();
    }, []);

    return (
        <div className="App">
            <div className="section section1" style={{
                backgroundImage: "url(" + bgMain + ")",
                backgroundPositionX: 'center',
                backgroundPositionY: 'bottom',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}>
                <header className="headerBox" style={{display: isMenuShow ? 'block' : 'none'}}>
                    <div className="w1200">
                        <img src={IClogo} className="Logo" alt="로고"/>
                    </div>
                </header>
                <div className="menu_box w1200">
                    <div className="logobox">
                        <img src={IClogow} className="Logo" alt="로고"/>
                    </div>
                    <div className="siteTitle">
                        <h2>나는 모바일에서<br/>간편하게 중장비 부른다!</h2>
                        <span className="siteInfo">
                            내손안에 중장비 배차 사무실 '장비파트너'<br/>전국 중장비 배차, 베테랑 장비 기사 배정
                        </span>
                    </div>
                    <div className="bottomBox">
                        <div className="bottomLeft">중장비 작업이 필요하신가요?<br/>난이도가 높은 작업인가요?<br/>베테랑 장비 기사가 필요하신가요?</div>
                        <div className="bottomRight">
                            <h2>장비파트너 APP다운로드</h2>
                            <div className="bottominBox">
                                <h3>발주사, 장비 사업자, 장비 기사</h3>
                                <button className="roundbox-white" onClick={() => {
                                    goAppstore();
                                }}>
                                    <img src={IcApple} className="goIcon" alt="애플"/>
                                    <span className="goText">앱스토어</span>
                                </button>
                                <button className="roundbox-white" onClick={() => {
                                    goPlaystore();
                                }}>
                                    <img src={IcGoogle} className="goIcon" alt="구글"/>
                                    <span className="goText">구글플레이</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="go_box">
                    <div className="w1200">
                        <button className="roundbox-black" onClick={() => {
                            goTel();
                        }}>
                            <img src={IcTel} className="goIcon" alt="전화"/>
                            <span className="goText">
                                장비상담문의<br/>1555-2838
                            </span>
                        </button>
                        <button className="roundbox-blue" onClick={() => {
                            goOrder();
                        }}>
                            <img src={IcOrder} className="goIcon" alt="발주사"/>
                            <span className="goText">
                                웹에서 중장비<br/>바로 주문하기
                            </span>
                        </button>
                        <button className="roundbox-navi" onClick={() => {
                            showSMS();
                        }}>
                            <img src={IcDevice} className="goIcon" alt="장비사업자"/>
                            <span className="goText">
                                장비, 기사<br/>입점신청
                            </span>
                        </button>
                    </div>
                </div>
                <div className="goTelBox">
                    <button onClick={(e) => {
                        goTel();
                    }}>
                        <img src={IcWTel} />
                        <span>전화<br />상담</span>
                    </button>
                </div>
            </div>
            {orders.length > 0 && (
                <div className="section section5" style={{
                    backgroundImage: "url(" + bgNowList + ")",
                    backgroundPositionX: 'right',
                    backgroundPositionY: 'bottom',
                    backgroundRepeat: 'no-repeat',
                }}>
                    <div className="w1200">
                        <h2>실시간 배차요청</h2>
                        <div className="list_box">
                            <div className="live_now_list">
                                <ul>
                                    {orders.map((item, index) => (
                                        <li key={index}  onClick={(e) => {
                                            e.preventDefault();
                                            window.alert("상세정보는 장비파트너 서비스 회원가입 후 확인할 수 있습니다.");
                                        }}>
                                            <LiveNowItem item={item} index={index} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="live_now_button">
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    setIsOrder(true);
                                }}>
                                    <img src={IcQuest} alt="견적"/>
                                    <span>견적 문의하기</span>
                                </button>
                            </div>
                        </div>
                        <div className="bottomBox" style={{
                            backgroundImage: "url(" + bgWShadow + ")",
                            backgroundPositionX: 'center',
                            backgroundPositionY: 'top',
                            backgroundRepeat: 'repeat-x',
                        }}>
                            <div className="bottomButton" style={{
                                backgroundImage: "url(" + bgBButton + ")",
                                backgroundPositionX: 'center',
                                backgroundPositionY: 'top',
                                backgroundRepeat: 'no-repeat',
                            }}>
                                <div className="bottomLeft">더 많은 중장비 배차건을 확인하시려면<br/>장비파트너 APP 다운후 <br/>회원가입 해주세요</div>
                                <div className="bottomRight">
                                    <div className="bottominBox">
                                        <h3>발주사, 장비 사업자, 장비 기사</h3>
                                        <button className="roundbox-white" onClick={() => {
                                            goAppstore();
                                        }}>
                                            <img src={IcApple} className="goIcon" alt="애플"/>
                                            <span className="goText">앱스토어</span>
                                        </button>
                                        <button className="roundbox-white" onClick={() => {
                                            goPlaystore();
                                        }}>
                                            <img src={IcGoogle} className="goIcon" alt="구글"/>
                                            <span className="goText">구글플레이</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="section section2">
                <div className="w1200">
                    <h2><strong>장비파트너</strong>는<br/>실력있는 검증된 장비, 기사님을 배차합니다.</h2>
                    <div className="modelBox">
                        <h3>장비파트너 배차 장비 종류</h3>
                        <span className="section2_info">
                            장비파트너는 다양한 작업에 필요한 중장비를 배차하고 있습니다.
                        </span>
                        <ul>
                            <li>
                                <span className="modelTitle">굴착기</span>
                                <img src={IcModel1} className="modelIcon" alt="굴착기"/>
                            </li>
                            <li>
                                <span className="modelTitle">크레인</span>
                                <img src={IcModel2} className="modelIcon" alt="크레인"/>
                            </li>
                            <li>
                                <span className="modelTitle">지게차</span>
                                <img src={IcModel3} className="modelIcon" alt="지게차"/>
                            </li>
                            <li>
                                <span className="modelTitle">백호로더</span>
                                <img src={IcModel4} className="modelIcon" alt="백호로더"/>
                            </li>
                            <li>
                                <span className="modelTitle">롤러</span>
                                <img src={IcModel5} className="modelIcon" alt="롤러"/>
                            </li>
                            <li>
                                <span className="modelTitle">덤프트럭</span>
                                <img src={IcModel6} className="modelIcon" alt="덤프트럭"/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr />
            <div className="section section2-1">
                <div className="w1200">
                    <div className="quickBox" style={{
                        backgroundImage: "url(" + bgQuick + ")",
                        backgroundPositionX: blogs.length > 0 ? '0px' : 'center',
                        backgroundPositionY: 'bottom',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <h3>장비파트너 전국 신속배차</h3>
                        <span className="section2_info">
                            장비파트너는 전국 중장비 배차망 보유로 대한민국 전국 어디든지 책임 배차합니다.
                        </span>
                        {blogs.length > 0 && (
                            <div className="blogBox">
                                {blogs.map((item, index) => (
                                    <button key={index} onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/blog?type=&page=1&seq=' + item.seq;
                                    }}>
                                        <img src={item.tumb} />
                                        <span className="blogTitle">{item.title}</span>
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="section section3">
                <div className="w1200">
                    <h2>중장비 사용시 겪는 문제점</h2>
                    <span className="section3_info">
                        장비파트너와 함께 하시는 고객께서는 이런 고민을 하고 계셨습니다.
                    </span>
                    <ul>
                        <li>
                            <img src={IcProp1} className="propIcon" alt="문제1"/>
                            <span className="propInfo">작업단가가 합리적인지 모르겠어요<br/><strong>비교견적</strong>이 필요해요.</span>
                        </li>
                        <li>
                            <img src={IcProp2} className="propIcon" alt="문제2"/>
                            <span className="propInfo"><strong>믿을만한 장비업체</strong>와<br/>거래하고 싶어요.</span>
                        </li>
                        <li>
                            <img src={IcProp3} className="propIcon" alt="문제3"/>
                            <span className="propInfo"><strong>정확</strong>하고 <strong>신속</strong>한<br/>배차가 필요해요.</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="section section4">
                <div className="w1200">
                    <h2><strong>장비파트너</strong>와 함께 해보세요</h2>
                    <ul>
                        <li className="list1" style={{
                            backgroundImage: "url(" + bgSolution1 + ")",
                            backgroundPositionX: 'left',
                            backgroundPositionY: 'top',
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <h3>
                                <span className="topTitle">
                                    SOLUTION 1
                                </span>
                                <span className="inTitle">
                                    합리적인 선택으로 중장비를 이용하실 수 있습니다
                                </span>
                            </h3>
                            <span className="listInfo">
                                발주 등록 후 지원된 업체 비교견적, 기사 년차 등 선택에 필요한 정보를 사전 제공합니다. 업체 연결 후에는 기등록 필수서류 제공으로 공사에 차질 없이 장비를 투입 할 수 있습니다.
                            </span>
                            <span className="listBottom">비교 견적 제공</span>
                        </li>
                        <li className="list2" style={{
                            backgroundImage: "url(" + bgSolution2 + ")",
                            backgroundPositionX: 'left',
                            backgroundPositionY: 'top',
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <h3>
                                <span className="topTitle">
                                    SOLUTION 2
                                </span>
                                <span className="inTitle">
                                    어려운 작업도 믿고 맡길 수 있습니다
                                </span>
                            </h3>
                            <span className="listInfo">
                                등록전 장비관련 법정 필수서류 검토, 기사 이력 사전검토 등 검증된 장비, 기사를 우선 배정해드립니다.
                            </span>
                            <span className="listBottom">사전 검증된 장비업체, 베테랑 기사 투입</span>
                        </li>
                        <li className="list3" style={{
                            backgroundImage: "url(" + bgSolution3 + ")",
                            backgroundPositionX: 'left',
                            backgroundPositionY: 'top',
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <h3>
                                <span className="topTitle">
                                    SOLUTION 3
                                </span>
                                <span className="inTitle">
                                    빠르고 간편하게 배차 서비스를 이용할 수 있습니다
                                </span>
                            </h3>
                            <span className="listInfo">
                                현장 근거리 우수 장비업체를 연결하여 가장 빠르고 정확하게 배정해 드립니다. 배차후에 어플내에서 번거로운 계약서 작성, 작업일보 공유 등을 간편하게 진행하실 수 있습니다.
                            </span>
                            <span className="listBottom">전국 중장비 배차망 보유 근거리 장비 우선 배차<br/>반자동 계약 및 작업일보 공유기능</span>
                        </li>
                        <li className="list4" style={{
                            backgroundImage: "url(" + bgSolution4 + ")",
                            backgroundPositionX: 'left',
                            backgroundPositionY: 'top',
                            backgroundRepeat: 'no-repeat',
                        }}>
                            <h3>
                                <span className="topTitle">
                                    SOLUTION 4
                                </span>
                                <span className="inTitle">
                                    장파매니저 배차 A/S 진행
                                </span>
                            </h3>
                            <span className="listInfo">
                                장파매니저를 통하여 배차 관련 불만족사항, 문의사항, 애로사항 등을 접수하여 개선된 배차 서비스를 받으실 수 있습니다. 장비파트너는 고객께서 100% 만족하시는 중장비 배차서비스를 추구합니다.
                            </span>
                            <span className="listBottom">고객만족 100% 중장비 배차</span>
                        </li>
                    </ul>
                </div>
            </div>
            <footer>
                <div className="w1200">
                    <span>상호 : 주식회사 세움웍스</span>
                    <span>장파 고객센터 : 1555-2838 (오전8시~오후6시)</span><br/>
                    <span>사업자 등록번호 : 177-81-03460</span>
                    <span>이메일 : jangpa0101@gmail.com</span>
                    <span>주소 : 천안시 서북구 불당14로 48</span><br/>
                    <span>직업정보제공사업면허 : J1721020230004호</span>
                    <span className="copy">
                        &copy; 2023 by Jangpa.co.kr
                    </span>
                    <div className="quickLink">
                        <button onClick={() => {
                            goBlog();
                        }}>
                            <img src={IcBlog} className="quickIcon" alt="블로그이동"/>
                        </button>
                        <button onClick={() => {
                            goYoutube();
                        }}>
                            <img src={IcYoutube} className="quickIcon" alt="유튜브이동"/>
                        </button>
                    </div>
                </div>
            </footer>
            <div className="downloadBox" style={{display: isDownShow ? 'block' : 'none'}}>
                <div className="w1200">
                    <div className="downloadButtons">
                        <h2>
                            <img src={IcDown} alt="다운로드"/>
                            <span>앱다운로드</span>
                        </h2>
                        <button onClick={() => {
                            goAppstore();
                        }}>
                            <img src={IcApple} className="goIcon" alt="애플"/>
                            <span className="goText">앱스토어</span>
                        </button>
                        <button onClick={() => {
                            goPlaystore();
                        }}>
                            <img src={IcGoogle} className="goIcon" alt="구글"/>
                            <span className="goText">구글플레이</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="callSMS" style={{display: isSMS ? 'block' : 'none'}}>
                <div className="showBox">
                    <h2>장비, 기사 입점신청</h2>
                    <div className="inbox">
                        <label>성명</label>
                        <input type="text" placeholder="성명을 입력해주세요." value={name} onChange={(element) => {
                            setName(element.target.value);
                        }}/>
                        <label>연락처</label>
                        <input type="text" placeholder="연락처를 입력해주세요." value={mobile} onChange={(element) => {
                            setMobile(element.target.value);
                        }}/>
                    </div>
                    <div className="buttonBox">
                        <button onClick={() => {
                            sendSMS();
                        }}>입점신청
                        </button>
                        <button onClick={() => {
                            setIsSMS(false);
                        }}>닫기
                        </button>
                    </div>
                </div>
            </div>
            <OrderModal isShow={isOrder} callback={(type, data) => {
                switch (type) {
                    case 'close' :
                        setIsOrder(false);
                        break;
                }
            }}/>
        </div>
    );
}

export default App;
